import React, {useEffect, useState} from 'react';
import { navigate } from '@reach/router';
import {GET_HEXOMETER_USER_SETTINGS, ME} from '../../../graphql/queries';
import {useLazyQuery, useQuery} from '@apollo/client';
import PaymentInfo from '../../../components/payment-add-more';
import '../styles.scss';
interface ICard {
  productName: string;
  amount: number;
  id: string;
  interval: string;
  maxStackCount: string;
  max_frequency: string;
  isPopular: boolean;
  priceId: string;
  description: {
    page_limit_per_website: string;
    agency_growth_tool: string;
    availability_monitoring: string;
    max_properties_count: string;
  };
  productType: 'STRIPE' | 'PAYPAL';
}

interface ICard {
  productName: string;
  amount: number;
  id: string;
  interval: string;
  maxStackCount: string;
  max_frequency: string;
  isPopular: boolean;
  priceId: string;
  description: {
    page_limit_per_website: string;
    agency_growth_tool: string;
    availability_monitoring: string;
    max_properties_count: string;
  };
  productType: 'STRIPE' | 'PAYPAL';
}
const pricing = [
  {
    packageName: null,
    price: null,
    uniqueHostName: 'Monitored websites',
    page_limit_per_website: 'Page limit per website',
    smart_crawl: 'Smart crawl',
    on_demand_reports: 'On-demand reports',
    availability_monitoring: {
      title: 'Availability monitoring',
      uptime_monitoring_frequency: 'Uptime monitoring frequency',
      standard_uptime_monitors: 'Standard uptime monitors',
      advanced_monitors: 'Advanced monitors',
      whitelabeled_status_pages: 'White-labeled status pages',
    },
    user_experience_monitoring: {
      title: 'User experience monitoring',
      spellcheck_and_grammar_checks: 'Spelling and grammar checks in 30+ languages',
      in_depth_multi_device_UX_checks: 'In depth multi-device UX checks',
    },
    performance_monitoring: {
      title: 'Performance monitoring',
      in_depth_performance_checks: 'In depth performance checks',
    },
    health_monitoring: {
      title: 'Health monitoring',
      broken_links_error_monitoring: 'Broken links & error monitoring',
      in_depth_W3C_JS_error_checks: 'In depth W3C & JS error checks',
    },
    seo_monitoring: {
      title: 'SEO monitoring',
      search_engine_optimization_audit: 'Search engine optimization audit',
      google_search_console: 'Google search console',
    },
    security_monitoring: {
      title: 'Security monitoring',
      domain_level_web_safe_security_audit: 'Domain level web safe & security audit',
      iP_address_blacklist_checks: 'IP address blacklist checks',
    },
    notification_channels_integrations: {
      title: 'Notification channels & integrations',
      Email: ' Email',
      Telegram_slack_trello_discord: ' Telegram, Slack, Trello, Discord',
      webhooks_zapier_pabbly_integrately: ' Webhooks, Zapier, Pabbly Connect, Integrately, SyncSpider',
      // sms: ' ',
    },
    reporting: {
      title: 'Reporting',
      exports_CSV_G_Sheets_PDF_whitelabeled: 'Exports to: CSV, G Sheets, PDF white-labeled',
    },
  },
  {
    packageName: 'Standard',
    price: '20',
    uniqueHostName: 2,
    page_limit_per_website: 10000,
    agency_growth_tools: 50,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 5,
      advanced_monitors: false,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      spellcheck_and_grammar_checks: '50 / mo',
      in_depth_multi_device_UX_checks: '10 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '50 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '50 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 2,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '10 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
  {
    packageName: 'Advanced',
    price: '50',
    uniqueHostName: 3,
    page_limit_per_website: 25000,
    agency_growth_tools: 100,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 10,
      advanced_monitors: 1,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      spellcheck_and_grammar_checks: '100 / mo',
      in_depth_multi_device_UX_checks: '15 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '100 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '100 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 10,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '30 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
  {
    packageName: 'Guru',
    price: '100',
    uniqueHostName: 5,
    page_limit_per_website: 50000,
    agency_growth_tools: 150,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 25,
      advanced_monitors: 2,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      spellcheck_and_grammar_checks: '200 / mo',
      in_depth_multi_device_UX_checks: '25 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '200 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '200 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 25,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '50 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
];

const pricingAgency = [
  {
    packageName: null,
    price: null,
    uniqueHostName: 'Monitored websites',
    page_limit_per_website: 'Page limit per website',
    agency_growth_tools: 'Agency growth tools',
    smart_crawl: 'Smart crawl',
    on_demand_reports: 'On-demand reports',
    availability_monitoring: {
      title: 'Availability monitoring',
      uptime_monitoring_frequency: 'Uptime monitoring frequency',
      standard_uptime_monitors: 'Standard uptime monitors (1 minute checks)',
      advanced_monitors: 'Advanced monitors',
      whitelabeled_status_pages: 'White-labeled status pages',
    },
    user_experience_monitoring: {
      title: 'User experience monitoring',
      spellcheck_and_grammar_checks: 'Spelling and grammar checks in 30+ languages',
      in_depth_multi_device_UX_checks: 'In depth multi-device UX checks',
    },
    performance_monitoring: {
      title: 'Performance monitoring',
      in_depth_performance_checks: 'In depth performance checks',
    },
    health_monitoring: {
      title: 'Health monitoring',
      broken_links_error_monitoring: 'Broken links & error monitoring',
      in_depth_W3C_JS_error_checks: 'In depth W3C & JS error checks',
    },
    seo_monitoring: {
      title: 'SEO monitoring',
      search_engine_optimization_audit: 'Search engine optimization audit',
      google_search_console: 'Google search console',
    },
    security_monitoring: {
      title: 'Security monitoring',
      domain_level_web_safe_security_audit: 'Domain level web safe & security audit',
      iP_address_blacklist_checks: 'IP address blacklist checks',
    },
    notification_channels_integrations: {
      title: 'Notification channels & integrations',
      Email: ' Email',
      Telegram_slack_trello_discord: ' Telegram, Slack, Trello',
      webhooks_zapier_pabbly_integrately: ' Webhooks, Zapier, Pabbly Connect, Integrately, SyncSpider',
      // sms: ' SMS',
    },
    reporting: {
      title: 'Reporting',
      exports_CSV_G_Sheets_PDF_whitelabeled: 'Exports to: CSV, G Sheets, PDF white-labeled',
    },
  },

  {
    packageName: 'Standard',
    price: '20',
    uniqueHostName: 10,
    page_limit_per_website: 10000,
    agency_growth_tools: 50,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 30,
      advanced_monitors: false,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      spellcheck_and_grammar_checks: '150 / mo',
      in_depth_multi_device_UX_checks: '50 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '200 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '200 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 50,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '25 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
  {
    packageName: 'Advanced',
    price: '50',
    uniqueHostName: 15,
    page_limit_per_website: 25000,
    agency_growth_tools: 100,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 40,
      advanced_monitors: 4,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      spellcheck_and_grammar_checks: '250 / mo',
      in_depth_multi_device_UX_checks: '75 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '450 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '450 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 100,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '50 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
  {
    packageName: 'Guru',
    price: '100',
    uniqueHostName: 20,
    page_limit_per_website: 50000,
    agency_growth_tools: 150,
    smart_crawl: true,
    on_demand_reports: true,
    availability_monitoring: {
      title: '',
      uptime_monitoring_frequency: '1 min',
      standard_uptime_monitors: 60,
      advanced_monitors: 6,
      whitelabeled_status_pages: true,
    },
    user_experience_monitoring: {
      title: '',
      spellcheck_and_grammar_checks: '500 / mo',
      in_depth_multi_device_UX_checks: '100 / mo',
    },
    performance_monitoring: {
      title: '',
      in_depth_performance_checks: '750 / mo',
    },
    health_monitoring: {
      title: '',
      broken_links_error_monitoring: true,
      in_depth_W3C_JS_error_checks: '750 / mo',
    },
    seo_monitoring: {
      title: '',
      search_engine_optimization_audit: true,
      google_search_console: true,
    },
    security_monitoring: {
      title: '',
      domain_level_web_safe_security_audit: true,
      iP_address_blacklist_checks: 200,
    },
    notification_channels_integrations: {
      title: '',
      Email: true,
      Telegram_slack_trello_discord: true,
      webhooks_zapier_pabbly_integrately: true,
      // sms: '75 SMS/mo',
    },
    reporting: {
      title: '',
      exports_CSV_G_Sheets_PDF_whitelabeled: true,
    },
  },
];

export const newPackageDescriptions: any = [];

const Checkout = ({pageContext}: any) => {
  const ltd = false;
  const promo = false;
  const [showPaypalMessage, setShowPaypalMessage] = useState(false);
  const [paypal_subscription_id, setPaypalSubscriptionId] = useState('');
  const [stripe_subscription_id, setStripeSubscriptionId] = useState('');
  const [annually, setAnnually] = useState(false);
  const [pendingPayment, setPendingPayment] = useState(false);
  const [agency, setAgency] = useState(!!pageContext.agency);
  const [showMore, setShowMore] = useState(false);
  const [pr, setPr] = useState(pricing);
  const [currentPackage, setCurrentPackage] = useState('');
  const [currentPackageInterval, setCurrentPackageInterval] = useState('');
  const [user, setUser] = useState(null as any);
  const [item, setItem] = useState(pricing[0]);
  const [selectedPackage, setSelectedPackage] = useState({} as any);
  const [showLTD, setShowLTD] = useState(false);
  const [showTrial, setShowTrial] = useState(true);
  const [trialList, setTrialList] = useState<any>(['STANDARD']);

  const [hexometerPackages, setHexometerPackages] = useState<{
    annual: any;
    monthly: any;
    ltd: any;
  }>({annual: {}, monthly: {}, ltd: {}});

  const [updatedHexometerPackages, setUpdatedHexometerPackages] = useState<{
    annual: any;
    monthly: any;
    ltd: any;
  }>({annual: {}, monthly: {}, ltd: {}});
  const [showModal, setShowModal] = useState(false);
  
  const {data: userData} = useQuery(ME, {
    fetchPolicy: 'no-cache'
  });

  const [getHexometerUser, {data: hexometerUser}] = useLazyQuery(GET_HEXOMETER_USER_SETTINGS, {
    fetchPolicy: 'no-cache',
    variables: {user_id: user?.id && +user.id},
  });

  useEffect(() => {
    const selPlan = localStorage.getItem('selected-plan');
    selPlan && setSelectedPackage(JSON.parse(selPlan));
  }, []);

  useEffect(() => {
    //@ts-ignore
    setItem(agency ? pricingAgency[0] : pricing[0]);
  }, [agency]);

  useEffect(() => {
    if (hexometerUser?.HexometerUserSettings?.get) {
      setPaypalSubscriptionId(hexometerUser.HexometerUserSettings.get.paypal_subscription_id);
      setStripeSubscriptionId(hexometerUser.HexometerUserSettings.get.stripe_subscription_id);
      setCurrentPackage(hexometerUser.HexometerUserSettings.get.pricing_package);
      setCurrentPackageInterval(hexometerUser.HexometerUserSettings.get.pricing_package_interval);
      setPendingPayment(!!hexometerUser.HexometerUserSettings.get.pending_payment);
      setTrialList(hexometerUser.HexometerUserSettings.get.trial_packages);
      typeof hexometerUser.HexometerUserSettings.get.can_use_trial === 'boolean' &&
        setShowTrial(hexometerUser.HexometerUserSettings.get.can_use_trial);
    }
  }, [hexometerUser]);

  useEffect(() => {
    if (userData?.User?.get) {
      setUser(userData.User.get);
      if (!userData.User.get.id) {
        navigate('/pricing/#registration');
      }
    }
  }, [userData]);

  useEffect(() => {
    user && user.id && getHexometerUser();
  }, [user]);

  const findPackeage = async (id: string) => {
    if (!hexometerPackages.annual.Regular) return;
    const all =
      [
        ...hexometerPackages.ltd.Regular, ...hexometerPackages.ltd.Agency,
          ...hexometerPackages.monthly.Regular,
          ...hexometerPackages.monthly.Agency,
          ...hexometerPackages.annual.Regular,
          ...hexometerPackages.annual.Agency,
        ];
    const el = all.find((item: any) => item.id === id);
    
    if (!el) return;
    const localStorageRawItem = localStorage.getItem('selected-plan');
    const localStorageSel = localStorageRawItem && JSON.parse(localStorageRawItem);

    setAnnually(el.interval !== 'MONTHLY');
    if (!el.disabled && el.name !== 'Free') {
      setAgency(el.name.includes('Agency'));
      if (localStorageSel && localStorageSel.trial && showTrial) {
        setSelectedPackage({...el, name: `${el.name} (7-day free trial)`, trial: true});
        localStorage.setItem('selected-plan', JSON.stringify({...el, name: `${el.name} (7-day free trial)`, trial: true}));
      } else {
        setSelectedPackage({...el});
        localStorage.setItem('selected-plan', JSON.stringify({...el}));
      }
      return;
    }
    const notDisabled = all.find(
      (item: any) => !item.disabled && item.packageName !== 'Free' && item.interval === el.interval,
    );
    if (notDisabled) {
      if (localStorageSel && localStorageSel.trial && showTrial) {
        setSelectedPackage({...notDisabled, name: `${notDisabled.name} (7-day free trial)`, trial: true});
        localStorage.setItem('selected-plan', JSON.stringify({...notDisabled, name: `${notDisabled.name} (7-day free trial)`, trial: true}));
      } else {
        setSelectedPackage({...notDisabled});
        localStorage.setItem('selected-plan', JSON.stringify({...notDisabled}));
      }
      setAgency(notDisabled.name.includes('Agency'));
    }
  };


  useEffect(() => {
    if (
      user &&
      user.id &&
      // user.verified &&
      localStorage.getItem('selected-plan') &&
      hexometerPackages.annual.Regular &&
      hexometerPackages.annual.Regular.length &&
      currentPackage
    ) {
      const selPlan = localStorage.getItem('selected-plan');
      const val = selPlan && JSON.parse(selPlan);

      const id = val?.id;
      id && findPackeage(id);
    }
  }, [user, hexometerPackages]);

  useEffect(() => {
    if (pageContext?.products) {
      const packages_annual: any = {};
      const packages_ltd: any = {};
      const packages: any = {};
      const paypal_packages_annual: any = {};
      const paypal_packages_ltd: any = {};
      const paypal_packages: any = {};
      pageContext.products.map((item: ICard) => {
        const planInfo = pageContext.products.find(
          (subItem: any) => subItem.interval === 'LTD' && subItem.productName === item.productName,
        );
        let el = {
          id: showLTD && planInfo && item.interval === 'ANNUAL' ? planInfo.id : item.id,
          interval: item.interval,
          name: item.productName,
          price: item.amount,
          lineThrough: item.interval === 'LTD',
          trigger: item.interval === 'LTD' && item.amount,
          flag: item.isPopular,
          ltdPrice: showLTD && planInfo && item.interval === 'ANNUAL' ? planInfo.amount : null,
          discount:
            promo && item.interval === 'ANNUAL'
              ? item.productName.includes('Standard')
                ? 30
                : item.productName.includes('Advanced')
                ? 40
                : item.productName.includes('Guru')
                ? 50
                : null
              : null,
          real_price:
            item.interval === 'ANNUAL'
              ? pageContext.products.find(
                  (subItem: any) => subItem.interval === 'MONTHLY' && subItem.productName === item.productName,
                ).amount
              : null,

          disabled: true,
        };

        if (item.productName === 'Standard') {
          el.disabled = !currentPackage || (currentPackage && currentPackage !== 'FREE') ? true : false;
        } else if (item.productName === 'Agency Standard') {
          el.disabled =
            item.interval === 'ANNUAL' && showLTD
              ? currentPackage !== 'AGENCY_STANDARD' && currentPackageInterval === 'LTD'
                ? true
                : false
              : !currentPackage || (currentPackage && currentPackage !== 'FREE' && !currentPackage.includes('STANDARD'))
              ? true
              : false;
        } else if (item.productName === 'Advanced') {
          el.disabled =
            !currentPackage || (currentPackage && currentPackage !== 'FREE' && !currentPackage.includes('STANDARD'))
              ? true
              : false;
        } else if (item.productName === 'Agency Advanced') {
          el.disabled =
            item.interval === 'ANNUAL' && showLTD
              ? currentPackage !== 'AGENCY_ADVANCED' && currentPackageInterval === 'LTD'
                ? true
                : false
              : !currentPackage ||
                (currentPackage &&
                  currentPackage !== 'FREE' &&
                  !currentPackage.includes('STANDARD') &&
                  !currentPackage.includes('ADVANCED'))
              ? true
              : false;
        } else if (item.productName === 'Guru') {
          el.disabled =
            !currentPackage ||
            (currentPackage &&
              currentPackage !== 'FREE' &&
              !currentPackage.includes('STANDARD') &&
              !currentPackage.includes('ADVANCED'))
              ? true
              : false;
        } else {
          el.disabled = currentPackage !== 'AGENCY_GURU' && currentPackageInterval === 'LTD' ? true : false;
        }
        //@ts-ignore
        const info = (item.productName.includes('Agency') ? pricingAgency : pricing).filter(
          (pr: any) => pr.packageName && item.productName.includes(pr.packageName),
        );
        //@ts-ignore

        if (info.length) el = {...info[0], ...el};

        if (item.productType === 'PAYPAL') {
          //@ts-ignore
          if (item.interval === 'ANNUAL') paypal_packages_annual[item.productName] = el;
          else if (item.interval === 'LTD') paypal_packages_ltd[item.productName] = el;
          else if (item.interval === 'MONTHLY' && item.priceId !== 'none') paypal_packages[item.productName] = el;
        } else {
          //@ts-ignore
          if (item.interval === 'ANNUAL') packages_annual[item.productName] = el;
          else if (item.interval === 'LTD') packages_ltd[item.productName] = el;
          else if (item.interval === 'MONTHLY' && item.priceId !== 'none') packages[item.productName] = el;
        }
      });
      setUpdatedHexometerPackages({
        annual: {
          Regular: [
            // packages.Free,
            {...packages_annual.Standard, paypal_id: paypal_packages_annual.Standard.id},
            {...packages_annual.Advanced, paypal_id: paypal_packages_annual.Advanced.id},
            {...packages_annual.Guru, paypal_id: paypal_packages_annual.Guru.id},
          ],
          Agency: [
            // packages.Free,
            {...packages_annual['Agency Standard'], paypal_id: paypal_packages_annual['Agency Standard'].id},
            {
              ...packages_annual['Agency Advanced'],
              paypal_id: paypal_packages_annual['Agency Advanced']
                ? paypal_packages_annual['Agency Advanced'].id
                : null,
            },
            {...packages_annual['Agency Guru'], paypal_id: paypal_packages_annual['Agency Guru'].id},
          ],
        },
        ltd: {
          Regular: [
            // packages.Free,
            {
              ...packages_ltd.Standard,
              paypal_id: paypal_packages_ltd.Standard ? paypal_packages_ltd.Standard.id : null,
            },
            {
              ...packages_ltd.Advanced,
              paypal_id: paypal_packages_ltd.Advanced ? paypal_packages_ltd.Advanced.id : null,
            },
            {...packages_ltd.Guru, paypal_id: paypal_packages_ltd.Guru ? paypal_packages_ltd.Guru.id : null},
          ],
          Agency: [
            // packages.Free,
            {
              ...packages_ltd['Agency Standard'],
              paypal_id: paypal_packages_ltd['Agency Standard'] ? paypal_packages_ltd['Agency Standard'].id : null,
            },
            {
              ...packages_ltd['Agency Advanced'],
              paypal_id: paypal_packages_ltd['Agency Advanced'] ? paypal_packages_ltd['Agency Advanced'].id : null,
            },
            {
              ...packages_ltd['Agency Guru'],
              paypal_id: paypal_packages_ltd['Agency Guru'] ? paypal_packages_ltd['Agency Guru'].id : null,
            },
          ],
        },
        monthly: {
          Regular: [
            // packages.Free,
            {...packages.Standard, paypal_id: paypal_packages.Standard.id, trial: true, name: 'Standard (7-day free trial)'},
            {...packages.Standard, paypal_id: paypal_packages.Standard.id},
            {...packages.Advanced, paypal_id: paypal_packages.Advanced.id},
            {...packages.Guru, paypal_id: paypal_packages.Guru.id},
          ],
          Agency: [
            // packages.Free,
            {...packages['Agency Standard'], paypal_id: paypal_packages['Agency Standard'].id},
            {...packages['Agency Advanced'], paypal_id: paypal_packages['Agency Advanced'].id},
            {...packages['Agency Guru'], paypal_id: paypal_packages['Agency Guru'].id},
          ],
        },
      });
      setHexometerPackages({
        annual: {
          Regular: [
            // packages.Free,
            {...packages_annual.Standard, paypal_id: paypal_packages_annual.Standard.id},
            {...packages_annual.Advanced, paypal_id: paypal_packages_annual.Advanced.id},
            {...packages_annual.Guru, paypal_id: paypal_packages_annual.Guru.id},
          ],
          Agency: [
            // packages.Free,
            {...packages_annual['Agency Standard'], paypal_id: paypal_packages_annual['Agency Standard'].id},
            {
              ...packages_annual['Agency Advanced'],
              paypal_id: paypal_packages_annual['Agency Advanced']
                ? paypal_packages_annual['Agency Advanced'].id
                : null,
            },
            {...packages_annual['Agency Guru'], paypal_id: paypal_packages_annual['Agency Guru'].id},
          ],
        },
        ltd: {
          Regular: [
            // packages.Free,
            {
              ...packages_ltd.Standard,
              paypal_id: paypal_packages_ltd.Standard ? paypal_packages_ltd.Standard.id : null,
            },
            {
              ...packages_ltd.Advanced,
              paypal_id: paypal_packages_ltd.Advanced ? paypal_packages_ltd.Advanced.id : null,
            },
            {...packages_ltd.Guru, paypal_id: paypal_packages_ltd.Guru ? paypal_packages_ltd.Guru.id : null},
          ],
          Agency: [
            // packages.Free,
            {
              ...packages_ltd['Agency Standard'],
              paypal_id: paypal_packages_ltd['Agency Standard'] ? paypal_packages_ltd['Agency Standard'].id : null,
            },
            {
              ...packages_ltd['Agency Advanced'],
              paypal_id: paypal_packages_ltd['Agency Advanced'] ? paypal_packages_ltd['Agency Advanced'].id : null,
            },
            {
              ...packages_ltd['Agency Guru'],
              paypal_id: paypal_packages_ltd['Agency Guru'] ? paypal_packages_ltd['Agency Guru'].id : null,
            },
          ],
        },
        monthly: {
          Regular: [
            // packages.Free,
            {...packages.Standard, paypal_id: paypal_packages.Standard.id},
            {...packages.Advanced, paypal_id: paypal_packages.Advanced.id},
            {...packages.Guru, paypal_id: paypal_packages.Guru.id},
          ],
          Agency: [
            // packages.Free,
            {...packages['Agency Standard'], paypal_id: paypal_packages['Agency Standard'].id},
            {...packages['Agency Advanced'], paypal_id: paypal_packages['Agency Advanced'].id},
            {...packages['Agency Guru'], paypal_id: paypal_packages['Agency Guru'].id},
          ],
        },
      });
    }
  }, [pageContext.products, currentPackage, showLTD]);

  useEffect(() => {
    //@ts-ignores
    setPr(agency ? pricingAgency : pricing);
  }, [agency]);

  return selectedPackage && selectedPackage.name ? (
      <PaymentInfo
        name={selectedPackage.name}
        price={selectedPackage.ltd ? selectedPackage.ltdPrice : selectedPackage.price}
        setShowPopup={setShowModal}
        unVerified={user && !user.verified}
        packeges={
          (showTrial ? updatedHexometerPackages : hexometerPackages)[ltd ? 'ltd' : annually ? 'annual' : 'monthly'][
            agency ? 'Agency' : 'Regular'
          ]
        }
        ltdPrice={selectedPackage.ltdPrice}
        id={selectedPackage.id}
        refetchInfo={getHexometerUser}
        user={{
          ...user,
          pricing_package: currentPackage,
          paypal_subscription_id: paypal_subscription_id,
          stripe_subscription_id: stripe_subscription_id,
        }}
        paypal_id={selectedPackage.paypal_id}
        showPaypalMessage={showPaypalMessage}
        setShowPaypalMessage={setShowPaypalMessage}
        LTD
        showLTD={annually && agency && showLTD}
        trial={showTrial && !annually && !agency}
      />
    ) : null
};

export default Checkout;
